import Head from 'next/head';
import Image from 'next/image';
import Link from 'next/link';
import dynamic from 'next/dynamic';
import { useState } from 'react';

const ReactPlayer = dynamic(() => import('react-player'), { ssr: false });

import LockinIcon from '../public/images/Lock-In_Icon.svg';
import AreasSnapshot from '../public/images/Areas_Snapshot.png';
import ProjectsSnapshot from '../public/images/Project_Snapshot.png';
import IssuesSnapshot from '../public/images/Issues_Snapshot.png';
import InventorySnapshot from '../public/images/Inventory_Snapshot.png';

import PricingTable from '../components/PricingTable';
import Footer from '../components/Footer';
import LandingFeatureBlock from '../components/LandingFeatureBlock';
import TestimonialQuote from '../components/LandingPage/TestimonialQuote';
import Section from '../components/LandingPage/Section';

import { css } from '../styled-system/css';
import styles from '../styles/Landing.module.scss';

const DropdownMenu = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div style={{ position: 'relative' }}>
      <button
        className={css({ background: 'none', border: 'none', color: 'white' })}
        onClick={toggleDropdown}
      >
        Menu
      </button>
      {isOpen && (
        <div
          className={css({
            position: 'absolute',
            top: '100%',
            right: 0,
            backgroundColor: 'white',
            padding: '0rem 1rem',
            borderRadius: '0.5rem',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            zIndex: 1000,
            fontFamily: 'Inter, sans-serif',
            fontSize: 'body',
          })}
        >
          <a
            href="#features"
            className={css({
              display: 'block',
              margin: '0.5rem 0',
              textDecoration: 'none',
            })}
          >
            Features
          </a>
          <a
            href="https://docs.minicastle.app"
            target="_blank"
            rel="noopener noreferrer"
            className={css({
              display: 'block',
              margin: '0.5rem 0',
              textDecoration: 'none',
            })}
          >
            Docs
          </a>
          <a
            href="#pricing"
            className={css({
              display: 'block',
              margin: '0.5rem 0',
              textDecoration: 'none',
            })}
          >
            Pricing
          </a>
          <a
            href="/login"
            className={css({
              display: 'block',
              margin: '0.5rem 0',
              textDecoration: 'none',
            })}
          >
            Login
          </a>
        </div>
      )}
    </div>
  );
};

const Landing = () => {
  return (
    <>
      <Head>
        <title>Minicastle - Digital Power Tools for Fearless Homeowners</title>
        <meta
          name="description"
          content="Minicastle is the easiest, friendliest, and most powerful issue, project, and inventory management software for homeowners. Build, fix, upgrade, and enjoy your home."
        ></meta>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta
          property="og:title"
          content="Minicastle - Digital Power Tools for Fearless Homeowners"
        />
        <meta
          property="og:description"
          content="Minicastle is the easiest, friendliest, and most powerful issue, project, and inventory management software for homeowners. Build, fix, upgrade, and enjoy your home."
        />
        <meta property="og:image" content="/images/Minicastle_Logo.svg" />
        <meta property="og:url" content="https://www.minicastle.app" />
      </Head>
      <div className={styles.landing_body}>
        <nav className={styles.landing_nav}>
          <a>
            <Image
              src={'/Minicastle_Logo.svg'}
              width={178}
              height={32}
              alt="Minicastle Logo"
            />
          </a>
          <div className={styles.show_on_mobile}>
            <DropdownMenu />
          </div>
          <div className={styles.show_on_desktop}>
            <div className={styles.landing_nav_items}>
              <ul>
                <li>
                  <a href="#features">Features</a>
                </li>
                <li>
                  <a
                    className="plausible-event-name=Docs+Click"
                    href="https://docs.minicastle.app"
                  >
                    Docs
                  </a>
                </li>
                <li>
                  <a href="#pricing">Pricing</a>
                </li>
                <li>
                  <Link href={'/login'}>
                    <strong> Log in</strong>
                  </Link>
                </li>
              </ul>
            </div>{' '}
          </div>
        </nav>
        <main>
          <section className={styles.intro_section}>
            <h1 className={styles.intro_heading}>
              The knowledge base for your home.
            </h1>
            <p className={styles.intro_text}>
              Minicastle helps <strong>homeowners</strong> plan, document,
              build, and maintain magical places to live, work, and play.
            </p>
            <Link
              className={`${styles.landing_button} plausible-event-name=Banner+Get+Started+Button+Click`}
              href="/login"
              style={{
                marginTop: '1rem',
                marginBottom: '1rem',
                background: '#9747FF',
                color: 'white',
              }}
            >
              Get Started - it's Free!
            </Link>
            {/* Style per  */}
            <div
              className={css({
                transform: 'translateY(2rem)',
                zIndex: 5,
                width: '60%',
                margin: '0 auto',
                paddingBottom: '33.75%', // 16:9 aspect ratio (9/16 = 0.5625) * 60% width = 33.75%
                borderRadius: '1rem',
                overflow: 'hidden',
                lgDown: {
                  transform: 'translateY(1rem)',
                  width: '90%',
                  paddingBottom: '50.625%', // 16:9 aspect ratio (9/16 = 0.5625) * 90% width = 50.625%
                },
              })}
            >
              <ReactPlayer
                className={css({ position: 'absolute', top: 0, left: 0 })}
                url="https://youtu.be/aJOyELRihZE"
                width="100%"
                height="100%"
              />
            </div>
          </section>
          <section className={styles.feature_section} id={'features'}>
            <div className={styles.primary_features}>
              <LandingFeatureBlock
                name="Issues"
                title="Track and Improve Anything"
                type="primary"
                description={
                  'Whether you need to install, fix, replace, paint, or clean, Issues are here to help you get the job done.'
                }
                image={IssuesSnapshot}
              />
              <LandingFeatureBlock
                name="Areas"
                title="Prioritize & Modularize"
                type="primary"
                description={
                  'Intimidated by everything you need to do? Areas helps you breakdown work room-by-room, so you can enjoy progress at your own pace.'
                }
                image={AreasSnapshot}
              />
              <LandingFeatureBlock
                name="Projects"
                title="Turn Ideas into Reality"
                type="primary"
                description={
                  'Plan ambitiously, then take stock of what you accomplish. Projects bring your vision to life.'
                }
                image={ProjectsSnapshot}
              />
              <LandingFeatureBlock
                name="Inventory"
                title="Organize at Any Level"
                type="primary"
                description={
                  'Your equipment, appliances, systems, and stuff are all valuable tools. Track relationships, costs, quantities, and more.'
                }
                image={InventorySnapshot}
              />
            </div>
            <div>
              <div className={styles.secondary_features}>
                <h2>Collaborate & Coordinate</h2>
                <LandingFeatureBlock
                  title="Co-Owners"
                  icon="users"
                  description={
                    'Make it a party: invite unlimited roommates, partners, spouses, or friends to help manage anything.'
                  }
                />
                <LandingFeatureBlock
                  title="Calendar View"
                  icon="calendar-clock"
                  description={
                    'Plan your weekends, or give yourself a deadline to finally get that project done.'
                  }
                />
                <h2>Get Organized</h2>
                <LandingFeatureBlock
                  title="Links"
                  icon="link"
                  description={
                    'Help your future self by saving contextual details. Link to files, photos, receipts, manuals, and budgets.'
                  }
                />
                <LandingFeatureBlock
                  title="Suggestions"
                  icon="magic"
                  description={
                    'Not sure where to start? Discover suggestions as you work, or check out our guides for even more resources.'
                  }
                />
                <h2>Take Control</h2>
                <LandingFeatureBlock
                  title="Recurring Issues"
                  icon="recurring"
                  description={
                    'Stay on top of routine maintenance and chores with recurring issues that let you just set it and forget it.'
                  }
                />
                <LandingFeatureBlock
                  title="Digests & Reports"
                  icon="send"
                  description={
                    'Send yourself reminders about everything going on with your house.'
                  }
                />
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 4,
                marginTop: '5rem',
              }}
            >
              <Image src={LockinIcon} width={40} height={40} alt="" />
              <h3 style={{ margin: '1rem' }}>No Lock-In</h3>
            </div>
            <h2 style={{ color: 'white', margin: 0 }}>
              Export your data at any time
            </h2>
          </section>
          <section className={styles.comparison_section}>
            <h2>Purpose-built for the job</h2>
            <div className={styles.comparison_section_accordions}>
              <details
                className={'plausible-event-name=Spreadsheets+Compare+Click'}
              >
                <summary>Minicastle vs. Spreadsheets</summary>
                <ul>
                  <li>
                    <b>Structured & Connected:</b> Seamlessly view issues,
                    inventory, and projects by area, status, and more. Don't
                    waste time creating complicated logic to organize and link
                    your data.
                  </li>
                  <li>
                    <b>Effortlessly Collaborative:</b> Share with anyone, and
                    don't worry about finding or remembering specific links and
                    spreadsheet names. Everything is easy to find and visible in
                    a simple, concise interface.
                  </li>
                  <li>
                    <b>Phone Friendly:</b> Spreadsheets are painful to use when
                    you're away from your desk. Access your data on the move
                    with a mobile-friendly interface. No app download required.
                  </li>
                  <li>
                    <b>Purpose Built:</b> No need to re-invent the wheel! Enjoy
                    software designed to help you get things done faster and
                    better.
                  </li>
                </ul>
              </details>
              <details className={'plausible-event-name=PM+Compare+Click'}>
                <summary>Minicastle vs. Project Management Apps</summary>
                <ul>
                  <li>
                    <b>Purpose Built:</b> We prioritize features, functionality,
                    and experiences for homeowners. We shouldn't try to be
                    everything to everyone.
                  </li>
                  <li>
                    <b>Easy to Learn:</b> No learning curve or template-tweaking
                    required. Designed to get you up-and-running in minutes.
                  </li>
                  <li>
                    <b>Organized by Area:</b> The easiest way to understand
                    what's going on your house is to see issues room-by-room.
                    Get area-based views with no additional setup.
                  </li>
                  <li>
                    <b>No Downloads:</b> No more app downloads or updates. Enjoy
                    an always fresh, mobile-friendly experience on the go, or a
                    more spacious experience on your desktop.
                  </li>
                </ul>
              </details>
              <details className={'plausible-event-name=Todos+Compare+Click'}>
                <summary>Minicastle vs. Todo Lists</summary>
                <ul>
                  <li>
                    <b>Focused & Purpose-Built:</b> Your to-do list is full of
                    all kinds of tasks and reminders, not just house work.
                    Improve your home faster with a clear, concise list of
                    action items for your house.
                  </li>
                  <li>
                    <b>Organized:</b> View issues and work by area, date,
                    status, and more. All you have to do is walk into a room,
                    and you'll know exactly what you want to work on.
                  </li>
                  <li>
                    <b>Collaborative:</b> Instantly share with partners,
                    spouses, roommates, and any one else that shares your home.
                  </li>
                  <li>
                    <b>Help Your Future Self:</b> To-do items disappear as soon
                    as they're complete. Use completed issues to help you solve
                    problems in the future, especially by adding notes, links,
                    images, cost information, and more.
                  </li>
                </ul>
              </details>
              <details className={'plausible-event-name=Binders+Compare+Click'}>
                <summary>Minicastle vs. Notebooks & Binders</summary>
                <ul>
                  <li>
                    <b>Instantly Available:</b> Your information is available
                    wherever you are. No need to go ruffling through closets,
                    drawers, or notebooks for random pieces of paper.
                  </li>
                  <li>
                    <b>Organized & Efficient:</b> Find what you need with just a
                    few clicks, keywords, or less. Issues are immediately
                    organized by area, time, status, and more.
                  </li>
                  <li>
                    <b>Collaborative:</b> Instantly share with partners,
                    spouses, roommates, and anyone else that shares your home -
                    from anywhere. They don't need to remember anything other
                    than their email.
                  </li>
                </ul>
              </details>
              <details className={'plausible-event-name=Other+Compare+Click'}>
                <summary>Minicastle vs. Other Home Apps</summary>
                <ul>
                  <li>
                    <b>Built just for homeowners:</b> We're not here to sell you
                    ads, handyman services, home insurance, a new roof, or
                    another house. Our app is built just for you and what you
                    actually need. We have no customers other than homeowners.
                  </li>
                  <li>
                    <b>All-in-one:</b> We hope to be the only tool you need to
                    manage your home. No more bouncing between apps,
                    spreadsheets, and notebooks.
                  </li>
                  <li>
                    <b>In it for the long-run:</b> We use Minicastle to manage
                    our own home. As much as we enjoyed life before buying a
                    home, our goal is to keep owning a home (or two) for the
                    next 60+ years until we drop dead or move to space. Our plan
                    is to use Minicastle for the whole ride.
                  </li>
                </ul>
              </details>
            </div>
          </section>
          <section className={styles.suggestions_section}>
            <div>
              <svg
                title="Suggestions icon!"
                xmlns="http://www.w3.org/2000/svg"
                width="56"
                height="56"
                viewBox="0 0 33 33"
                fill="none"
              >
                <path
                  d="M23.04 12.9822L24.3275 10.1497L27.16 8.86219L24.3275 7.57469L23.04 4.74219L21.7525 7.57469L18.92 8.86219L21.7525 10.1497L23.04 12.9822ZM15.315 13.4972L12.74 7.83219L10.165 13.4972L4.5 16.0722L10.165 18.6472L12.74 24.3122L15.315 18.6472L20.98 16.0722L15.315 13.4972ZM23.04 19.1622L21.7525 21.9947L18.92 23.2822L21.7525 24.5697L23.04 27.4022L24.3275 24.5697L27.16 23.2822L24.3275 21.9947L23.04 19.1622Z"
                  fill="#FF8B5A"
                />
              </svg>
              <h2>
                Home Improvement is hard.
                <br /> Let’s help you figure out what’s next.
              </h2>
            </div>

            <p>
              Start with our interactive guides, or access{' '}
              <strong>Suggestions</strong> directly as you work.
            </p>
            <Link
              className={`${styles.suggestions_section_cta} plausible-event-name=Landing+Page+Homeowners+Guide+Click`}
              href="/guides/homeowners-guide"
              target="_blank"
            >
              Homeowners Guide 2024
            </Link>
          </section>
          <Section>
            <h2>Used by hundreds of homeowners, condo-owners, and renters.</h2>
            <TestimonialQuote />
          </Section>
          <Section name="pricing">
            <h2
              style={{
                color: 'white',
              }}
            >
              Minicastle helps you invest in your home, skills, or business.
            </h2>
            <Link
              className={`${styles.landing_button} plausible-event-name=Banner+Get+Started+Button+Click`}
              href="/login"
              style={{
                marginTop: '1rem',
                marginBottom: '6rem',
                background: '#9747FF',
                color: 'white',
              }}
            >
              Get Started
            </Link>
            <PricingTable />
          </Section>
        </main>
        <Footer />
      </div>
    </>
  );
};

export default Landing;

export async function getStaticProps() {
  return {
    props: {},
  };
}
